/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from 'react';
import router from 'next/router';
import Link from 'next/link';
// import Head from 'next/head';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import LazyLoad from 'react-lazyload';
import { UserContext } from '../components/UserContext';
import { trackGA_PageView, trackGA_Event } from '../utilsMethods/trackGA';
import fetchSwal from '../lib/fetchSwal';
import { SignUp } from '../components/signup';
import Layout from '../components/homeLayout';
import redirectTo from '../lib/redirectTo';
import Footer from '../components/Footer';
import { generateGuid } from '../utilsMethods/getGAGuid';
import { QueryPreservingLink } from '../components/QueryPreservingLink';

declare var fbq: any;

// import award1 from '../components/landing/award1';
// import award2 from '../components/landing/award2';
// import award3 from '../components/landing/award3';
// import award4 from '../components/landing/award4';
// import WistiaEmbed from '../components/wistia-embed';
// import { Quotes } from '../components/Icons';

export const DEFAULT_BLOCK_TEXT = {
  block1: <>We&apos;re no longer accepting and processing PPP Loan Applications</> as JSX.Element | string,
  block2: '',
  block3: 'You are eligible for a PPP loan from PPPays if you are a small or medium sized business or a nonprofit with less than 500 employees',
  block4: ['<500', 'Employees', 'Most business and nonprofits with 500 or fewer employees are qualified to apply'] as [string, string, string],
  block5: 'Happy to lend in any industry to small and mid size businesses, nonprofits and to independent contractors and sole proprietors.',
  block6: [
    'Restaurants',
    'Distributors',
    'Catering',
    'Bars',
    'Grocery Stores',
    'Convenience Stores',
    'Food Growers',
    'Construction',
    'Electrical',
    'Electrical',
    'HVAC',
    'Auto Repair',
    'Manufacturing',
    'Medical',
    'Entertainment',
    'Personal Grooming',
    'Hospitality',
    'Child Care',
  ],
  block7: [
    `Payroll Records`,
    `Full year of payroll processor records and / or payroll tax filings (e.g. Forms 940, 941, 944) covering January 2019 through February 2020. Note: Many payroll processors have created custom PPP reports for their customers in their online portals.`,

    `Identifying Business Information`,
    `Information to help identify your business such as a voided check, EIN / TIN information, proof of business (e.g. Articles of Incorporation)`,

    `Individual Applicant Identification`,
    `Identification documents for the individual applying on behalf of the business to validate date of birth and address such as a valid government issued ID and additional proof of address`,

    `Company Ownership Structure Documentation`,
    `Information on the ownership structure of the company (an individual can fill out the application on behalf of multiple owners) along with date of birth and other information on each of the owners with more than 20% stake in the company`,
  ] as [string, string, string, string, string, string, string, string],
};

const IndexPage: React.FunctionComponent<{ props?: typeof DEFAULT_BLOCK_TEXT; }> = ({ props = DEFAULT_BLOCK_TEXT }) => {
  const { dispatch } = useContext(UserContext);
  const [readyClass, setReadyClass] = useState('pagenotready');
  const [successMessage, setSuccessMessage] = useState('');
  const {
    state: {
      isLoggedIn,
    },
  } = useContext(UserContext);
  // There may be a better way to redirect when logged in // TODO
  useEffect(() => {
    // if (isLoggedIn) {
    //   router.replace('/form/instructions');
    // }
    if (readyClass === 'pagenotready') {
      setReadyClass('pageready');
    }

    if (readyClass === 'pageready') {
      trackGA_Event('Landing Page', 'load', 'Landing Page');
      trackGA_PageView(window.location.pathname + window.location.search);
    }
  }, [readyClass, isLoggedIn]);

  const handleSubmit = (user, cb) => {
    const paramsValue = generateGuid();
    const userBody = { ...user, ...paramsValue };
    // Create account
    fetchSwal.post('/api/users', userBody).then((data) => {
      if (data.ok !== false) {
        try {
          fbq('track', 'CompleteRegistration', {
            value: 0.00,
            currency: 'USD'
          });
        } catch (e) {
          console.error(e);
        }
        dispatch({ type: 'fetch' });
        trackGA_Event('Landing Page', 'load', 'Signed Up');
        redirectTo('/form/2483?step=0');
      } else if (cb) {
        cb();
      }
    }).catch((_err) => {
      if (cb) cb();
    });
  };

  return (
    <Layout transparent>
      <div className="homepage">
        <div className="hero-img-wrapper">
          <div className="hero-gradient">
            <Container maxWidth="xl" style={{ display: 'flex', padding: '0px' }}>
              <div className={`hero-tag-wrapper ${readyClass}`} style={{ display: 'inline-block' }}>
                <div className="hero-tag">
                  {props.block1}
                </div>
                <div className="hero-subtag">
                  {props.block2}
                </div>
              </div>
            </Container>
          </div>
        </div>
        <style jsx>
          {`
          .pageready {
            opacity: 1;
          }
          .pagenotready {
            opacity: 0;
          }
          .ourhistory {
            position: relative;
            background: #F7F8FA;
            padding: 120px 24px;
          }
          @media only screen and (min-width: 960px) {
            .quotes {
              position: absolute;
              top -80px;
            }
            .video-container {
              position: relative;
            }
            li {
              font-size: 24px !important;
            }
            .ourhistory {
              border-radius: 0 250px 0 0
            }
            :global(#__next) {
              border-radius: 0 0 0 250px;
            }
            .commitment {
              border-radius: 0 250px 0 0;
            }
            .hero-img-wrapper {
              border-radius: 0 0 0 250px;

              min-height: calc(750px - 76px);
              max-height: calc(1000px - 76px);
              background: linear-gradient(180deg, rgba(255,255,255,0) 13.19%, rgba(255, 255, 255, 0) 27.15%), linear-gradient(360deg, rgba(31, 36, 51, 0.5) 51.29%, rgba(31, 36, 51, 0) 58.7%);
            }
            :global(#__next) {
              min-height: 750px;
              max-height: 1000px;
            }
            .hero-video {
              padding: 16px 24px;
            }
          }
          .section1 .callout {
            max-width: 1400px;
            margin: auto;
            font-size: 36px;
          }
          :global(html) {
            background: white;
            overflow-x: hidden;
          }
          :global(nav) {
            background: unset !important;
          }
          :global(#__next) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80%;
            background: url('${require('../public/img/bg3.jpg')}');
            background-size: cover;
          }
          :global(main) {
          }
          ul {
            list-style-type: none;
          }
          li {
            color: #4B6075;
            font-size: 14px;
            font-weight: 300;
          }
          .industries {
            padding: 48px 24px;
            margin: 48px auto;
            max-width: 1400px;
          }
          .industry-hero-tag, .history-hero-tag {
            position: relative;
            color: #000000;
            font-size: 48px;
            font-family: 'Merriweather', serif;
            font-weight: 700;
          }
        .center {
          margin-top: 10px;
          text-align: center;
          font-size: 20px;
        }
        .commitment {
          padding: 32px 0;
          background: linear-gradient(75.06deg, #18307F 53.2%, #DF1E2E 128.22%);
          color: white;
        }
        .flip {
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        @media only screen and (max-width: 960px) {
          .quotes {
            display: none;
          }
          .section3 {
            padding: 40px 0 !important;
          }
          .section3 .pricing-large {
            margin: 10px 0 !important;
          }
          .section3 .price {
            font-size: 42px !important;
          }
          .section3 .strike {
            font-size: 24px !important;
            padding-left: 8px !important;
          }
          .section3 .unit {
            font-size: 18px !important;
            padding-left: 8px !important;
          }
          .section3 .callout {
            font-size: 20px !important;
            padding: 0 40px 20px !important;
          }
          .callout {
            font-size: 24px !important;
          }
          .tabletext {
            text-align: center;
            margin-left: auto !important;
            margin-right: auto !important;
          }
          .section3 .disclaimer {
            font-size: 16px !important;
            margin-bottom: 25px !important;
          }
          .section3 .pricing-small {
            font-size: 18px !important;
          }
          .section1 {
            padding-bottom: 0 !important;
            margin-top: 20px !important;
            padding-top: 60px !important;
            border-top: 1px solid #DCDDDE !important;
          }
          .commitment-mobile {
            margin-bottom: 40px !important;
            margin-top: 40px !important;
          }
          .section2 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
          .section2 .quoteText {
            font-size: 20px !important;
          }
          .hero-gradient {

          }
          section1 .callout {
            padding: 20px !important;
            font-size: 24px !important;
          }
          .hero-tag-wrapper {
            margin: auto !important;
          }
          .hero-tag {
            font-size: 32px !important;
            font-weight: 700 !important;
            line-height: 115%;
          }
          .our-history-hero-tag {
            font-size: 32px !important;
            font-weight: 700 !important;
            line-height: 115%;
          }
          .hero-subtag {
            font-size: 14px !important;
            font-weight: 300 !important;
          }
        }
        .float-right {
          background-color: white;
          box-sizing: border-box;
          border: 1px solid #4B6075;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 40px;
          float: right;
          margin: auto auto 36px auto;
          max-width: 450px;
          min-width: 450px;
        }
        .float-right h2 {
          text-align: center;
          font-size: 34px;
          font-weight: normal;
          margin-bottom: 0;
          margin-top: 0;
        }
        .float-right h3 {
          text-align: center;
          font-size: 16px;
          font-weight: normal;
          margin-top: 5px;
          margin-bottom: 10px;
          text-align: center;
          color: #4B6075;
        }
        .homepage :global(a.button),
        .homepage :global(a.button:visited),
        .homepage :global(a.button:active) {
          display: inline-block;
          color: white;
          background: #000000;
          border-radius: 5px;
          padding: 8px 16px;
          font-size: 20px;
        }
        .signupcallout {
          font-size: 24px;
          text-align: center;
          padding: 0 0 8px;
        }
        .signupsubcallout {
          font-size: 16px;
          text-align: center;
          color: #4B6075;
          padding: 0 0 16px;
        }
        .callout {
          font-color: color: #1F2933;
          font-size: 36px;
          font-weight: bold;
          line-height: 125%;
          text-align: center;
        }
        .hero-tag-wrapper {
          margin: auto auto 64px;
          max-width: 550px;
        }
        .hero-tag {
          color: white;
          font-size: 40px;
          padding: 32px 0;
          margin-left: 36px;
          width: auto;
          font-weight: 700;
        }
        .our-history-hero-tag {
          font-size: 48px;
          padding: 4px 0;
          width: auto;
          font-weight: 700;
        }
        p {
          color: #1F2433;
        }
        .hero-subtag {
          padding: 4px 0;
          margin-left: 36px;
          color: white;
          font-size: 20px;
          font-weight: 300;
        }
        .hero-img-wrapper {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: calc(80vh - 76px);
        }
        .hero-gradient {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
        .hero-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
        .section1 {
          background: white;
          padding: 60px 0px 0px 0px;
        }
        .section1 .table {
          margin-top: 32px;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }
        .section1 .table > div {
          text-align: center;
          width: 300px;
          font-size: 20px;
        }
        .tableitem {
          padding: 24px 24px;
          display: flex;
          flex-direction: column;
        }
        .tabletext {
          max-width: 500px;
          font-size: 14px;
        }
        .section1 .table .title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .section1 .table .icon {
          display: inline-block;
          padding-bottom: 16px;
          color: #000000;
          font-weight: 700;
          font-family: 'Merriweather', serif;
          font-size: 48px;
        }
        .section2 {
          // background: white;
          display: flex;
          flex-direction: row;
          margin-top: 140px;
          margin: 80px auto 80px auto;
        }
        .section2 .testimonyimage {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-height: 500px;
        }
        .section2 .left {
          width: 544px;
          font-size: 24px;
          padding: 80px 40px;
        }
        .section2 .quote {
          margin-bottom: 40px;
        }
        .homepage :global(a.button),
        .homepage :global(a.button:visited),
        .homepage :global(a.button:active) {
          display: inline-block;
          color: white;
          background: #000000;
          border-radius: 5px;
          padding: 8px 16px;
          font-size: 20px;
        }
        .section2 .quoteText {
          margin-bottom: 50px;
          font-size: 24px;
        }
        .section3 {
          text-align: center;
          padding-bottom: 140px;
        }
        .section3 .pricing-small {
          color: #2CA01C;
          font-size: 34px;
          margin-top: 20px;
        }
        .section3 .pricing-large {
          display: flex;
          margin-top: 20px;
          align-items: center;
          justify-content: center;
        }
        .section3 .price {
          color: #2CA01C;
          font-size: 120px;
          font-weight: bold;
        }
        .section3 .strike {
          font-size: 96px;
          text-decoration: line-through;
          padding-left: 60px;
        }
        .section3 .unit {
          font-size: 48px;
          padding-left: 18px;
        }
        .section3 .disclaimer {
          font-size: 24px;
          margin-bottom: 25px;
        }
        .underline {
          position: absolute;
          background: linear-gradient(30.96deg, #18307F 53.2%, #DF1E2E 128.22%);
          border-radius: 20px;
          transform: matrix(1, 0, 0, -1, 0, 0);
        }
        @media only screen and (max-width: 960px) {
          .hero-img-wrapper {
            max-height: 500px;
          }
          :global(#__next) {
            max-height: 500px;
            background-position-x: -200px;
          }
          .ourhistory {
            padding: 60px 24px;
          }
          .industry-hero-tag, .history-hero-tag {
            font-size: 24px !important;
          }
          .underline {
            display: none;
          }
        }
      `}
        </style>
      </div>
    </Layout>
  );
};
export default IndexPage;
