import React, { useContext } from 'react';
import Head from 'next/head';
import fetchSwal from '../lib/fetchSwal';
import { UserContext } from './UserContext';
import { Nav } from './nav.tsx';
import redirectTo from '../lib/redirectTo';

const siteMap = [];

export default ({ children, transparent=false }) => {
  const {
    state: { isLoggedIn, user },
    dispatch,
  } = useContext(UserContext);
  const handleLogout = (event) => {
    event.preventDefault();
    fetchSwal
      .delete('/api/session')
      .then((data) => data.ok !== false && dispatch({ type: 'clear' }))
      .then(() => redirectTo('/'));
  };

  return (
    <>
      <Head>
        <title>Home - PPPays</title>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta property="og:title" content="Covid Reserve" />
      </Head>
      <header>
        <Nav transparent={transparent} siteMap={siteMap} isLoggedIn={isLoggedIn} onLogOut={handleLogout} />
      </header>

      <main>
        {children}
      </main>
      <style jsx>
        {`
          header :global(nav .upper) {
            background-color: white !important;
          }
          header :global(nav .upper a) {
            color: black !important;
          }
          footer {
            text-align: center;
            font-size: 0.8rem;
            margin-top: 1rem;
            padding: 3rem;
            color: #888;
          }
        `}
      </style>
    </>
  );
};
